import React, { Component } from "react";
import * as yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";
import ReactGA from "react-ga";

import Layout from "../../components/Layout";
import { Heading1 } from "../../components/text";
import Optgroup from "../../components/Optgroup";
import Banner from "../../assets/images/Subway-Careers.jpg";
import locations from "../../data/locations.json";
import { groupBy, map } from "lodash";

const validationSchema = yup.object({
  Name: yup.string().label("Name").min(6).required(),
  Address: yup.string().label("Address").required(),
  "Date Of Birth": yup.date().label("Date of Birth").required(),
  Email: yup.string().label("Email").email(),
  Position: yup.string().label("Position").required(),
  Phone: yup.string().label("Phone").min(7).required(),
  Branch: yup.string().label("Branch").min(3).required(),
  "Can Work Shifts": yup.string().label("Can Work Shifts").required(),
  "Can Work Public Holidays": yup
    .string()
    .label("Can Work Public Holidays")
    .required(),
  "Completed Secondary School": yup
    .string()
    .label("Completed Secondary School")
    .required(),
  "Completed Secondary School": yup
    .string()
    .label("Completed Secondary School")
    .required(),
  "Completed Any Courses/Programmes": yup
    .string()
    .label("Completed Any Courses/Programmes")
    .required(),
  Referred: yup.string().label("Referred").required(),
});

const initialValues = {
  Name: "",
  Address: "",
  "Date Of Birth": "",
  Email: "",
  Position: "",
  Phone: "",
  Branch: "",
  "Have You Worked for PHL Previously": "",
  "Can Work Shifts": "",
  "Shift Reason": "",
  "Can Work Public Holidays": "",
  "Holidays Reason": "",
  "Completed Secondary School": "",
  "Other Certifications or Qualifications": "",
  "Other Certifications or Qualifications Details": "",
  "Completed Any Courses/Programmes": "",
  "Completed Courses/Programmes Details": "",
  Referred: "",
  "Referred Details": "",
};

class Careers extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    ReactGA.initialize("UA-114339672-1");
  }

  render() {
    const meta = [
      {
        name: "description",
        content:
          "Fullfil your potential. Come Join our TEAM, build your CAREER and reap the REWARDS",
      },
      {
        name: "keywords",
        content:
          "Subway, Trinidad, Jobs, Tobago, Trinidad Careers, Tobago Careers, Trinidad Vacancies, Tobago Vacancies",
      },
    ];
    const groupedLocations = groupBy(locations, "region");

    return (
      <Layout
        title="Careers"
        meta={meta}
        className="py-md-5 bg-white text-black"
      >
        <Container className="px-0 px-md-3" fluid>
          <Row NoGutters>
            <Col xs={12} md className="px-0 px-md-3">
              <img src={Banner} alt="" className="w-100" />
            </Col>
            <Col xs={12} md className="py-3 px-3 py-md-0">
              <Heading1 sm={1.25} md={2} className="text-secondary">
                Candidate Information Form
              </Heading1>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
              >
                {(formik) => (
                  <Form
                    ref={this.form}
                    method="POST"
                    name="careers"
                    action="/careers/success/"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={() => {
                      ReactGA.event({
                        category: "Submit",
                        action: "Careers Form Submitted",
                      });
                      formik.handleSubmit();
                    }}
                  >
                    <label className="d-none">
                      Don’t fill this out if you're human:{" "}
                      <input name="bot-field" />
                    </label>
                    <input type="hidden" name="form-name" value="careers" />
                    <FormGroup>
                      <Label for="Name">Name</Label>
                      <Field
                        as={Input}
                        name="Name"
                        placeholder="Name"
                        autoComplete="name"
                      />
                      <ErrorMessage
                        name="Name"
                        component="div"
                        className="invalid-feedback d-block"
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="Address">Address</Label>
                      <Field
                        as={Input}
                        type="textarea"
                        name="Address"
                        placeholder="Address"
                        autoComplete="street-address"
                      />
                      <ErrorMessage
                        name="Address"
                        component="div"
                        className="invalid-feedback d-block"
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="Date Of Birth">Date of Birth</Label>
                      <Field
                        as={Input}
                        type="date"
                        name="Date Of Birth"
                        autoComplete="bday"
                      />
                      <ErrorMessage
                        name="Date Of Birth"
                        component="div"
                        className="invalid-feedback d-block"
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="Phone">Phone</Label>
                      <Field
                        as={Input}
                        name="Phone"
                        placeholder="Enter Phone Number"
                        autoComplete="tel-local"
                      />
                      <ErrorMessage
                        name="Phone"
                        component="div"
                        className="invalid-feedback d-block"
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="Email">Email</Label>
                      <Field
                        as={Input}
                        name="Email"
                        placeholder="Enter email"
                        autoComplete="email"
                      />
                      <ErrorMessage
                        name="Email"
                        component="div"
                        className="invalid-feedback d-block"
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="Position">Position</Label>
                      <Field as={Input} type="select" name="Position">
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="Manager">Manager</option>
                        <option value="Supervisor">Supervisor</option>
                        <option value="Sandwich Artist">Sandwich Artist</option>
                        <option value="Delivery Driver">Delivery Driver</option>
                      </Field>
                      <ErrorMessage
                        name="Position"
                        component="div"
                        className="invalid-feedback d-block"
                      />
                    </FormGroup>

                    <fieldset
                      className="form-group"
                      style={{
                        display:
                          formik.values["Position"] === "Delivery Driver"
                            ? "block"
                            : "none",
                      }}
                    >
                      <legend>Do you have your own vehicle?</legend>
                      <Label className="custom-radio custom-control custom-control-inline">
                        <Field
                          as={Input}
                          type="radio"
                          name="Do you have your own vehicle?"
                          value="Yes"
                          label="Yes"
                          inline="true"
                        />
                        Yes
                      </Label>
                      <Label className="custom-radio custom-control custom-control-inline">
                        <Field
                          as={Input}
                          type="radio"
                          name="Do you have your own vehicle?"
                          value="No"
                          label="No"
                          inline="true"
                        />
                        No
                      </Label>
                      <ErrorMessage
                        name="Do you have your own vehicle?"
                        component="div"
                        className="invalid-feedback d-block"
                      />
                    </fieldset>

                    <FormGroup>
                      <Label for="Branch">
                        Please Indicate which Subway branch you are applying to:
                      </Label>
                      <Field
                        as={Input}
                        type="select"
                        name="Branch"
                        placeholder="Branch"
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        {map(groupedLocations, (items, label) => (
                          <Optgroup
                            key={label}
                            label={label}
                            items={items}
                            optValue="slug"
                            optLabel="location"
                          />
                        ))}
                      </Field>
                      <ErrorMessage
                        name="Branch"
                        component="div"
                        className="invalid-feedback d-block"
                      />
                    </FormGroup>

                    <fieldset className="form-group">
                      <legend>
                        Have you ever worked for KFC/PIZZA
                        HUT/TGIF/SUBWAY/STARBUCKS?
                      </legend>
                      <Label className="custom-radio custom-control custom-control-inline">
                        <Field
                          as={Input}
                          type="radio"
                          name="Have You Worked for PHL Previously"
                          value="Yes"
                          label="Yes"
                          inline="true"
                        />
                        Yes
                      </Label>
                      <Label className="custom-radio custom-control custom-control-inline">
                        <Field
                          as={Input}
                          type="radio"
                          name="Have You Worked for PHL Previously"
                          value="No"
                          label="No"
                          inline="true"
                        />
                        No
                      </Label>
                      <ErrorMessage
                        name="Have You Worked for PHL Previously"
                        component="div"
                        className="invalid-feedback d-block"
                      />
                    </fieldset>

                    <fieldset className="form-group">
                      <legend>Can you work on a shift basis?</legend>
                      <Label className="custom-radio custom-control custom-control-inline">
                        <Field
                          as={Input}
                          type="radio"
                          name="Can Work Shifts"
                          value="Yes"
                          label="Yes"
                          inline="true"
                        />
                        Yes
                      </Label>
                      <Label className="custom-radio custom-control custom-control-inline">
                        <Field
                          as={Input}
                          type="radio"
                          name="Can Work Shifts"
                          value="No"
                          label="No"
                          inline="true"
                        />
                        No
                      </Label>
                      <ErrorMessage
                        name="Can Work Shifts"
                        component="div"
                        className="invalid-feedback d-block"
                      />
                    </fieldset>

                    <FormGroup>
                      <Label for="Shift Reason">If No, state reason:</Label>
                      <Field
                        as={Input}
                        type="textarea"
                        name="Shift Reason"
                        placeholder="State reason"
                      />
                    </FormGroup>

                    <fieldset className="form-group">
                      <legend>Can you work on Public Holidays?</legend>
                      <Label className="custom-radio custom-control custom-control-inline">
                        <Field
                          as={Input}
                          type="radio"
                          name="Can Work Public Holidays"
                          value="Yes"
                          label="Yes"
                          inline="true"
                        />
                        Yes
                      </Label>
                      <Label className="custom-radio custom-control custom-control-inline">
                        <Field
                          as={Input}
                          type="radio"
                          name="Can Work Public Holidays"
                          value="No"
                          label="No"
                          inline="true"
                        />
                        No
                      </Label>
                      <ErrorMessage
                        name="Can Work Public Holidays"
                        component="div"
                        className="invalid-feedback d-block"
                      />
                    </fieldset>

                    <FormGroup>
                      <Label for="Holidays Reason">If No, state reason:</Label>
                      <Field
                        as={Input}
                        type="textarea"
                        name="Holidays Reason"
                        placeholder="State reason"
                      />
                    </FormGroup>

                    {/* <FormGroup>
                      <Label for="Completed Secondary School">Completed Secondary School</Label>
                      <Input
                        type="select"
                        name="Completed Secondary School"
                        onChange={console.log("TEST" + formik.values)}
                      >
                        <option selected value="" disabled>Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </Input>
                    </FormGroup> */}
                    <fieldset className="form-group">
                      <legend>Completed Secondary School</legend>
                      <Label className="custom-radio custom-control custom-control-inline">
                        <Field
                          as={Input}
                          type="radio"
                          name="Completed Secondary School"
                          value="Yes"
                          label="Yes"
                          inline="true"
                        />
                        Yes
                      </Label>
                      <Label className="custom-radio custom-control custom-control-inline">
                        <Field
                          as={Input}
                          type="radio"
                          name="Completed Secondary School"
                          value="No"
                          label="No"
                          inline="true"
                        />
                        No
                      </Label>
                      <ErrorMessage
                        name="Completed Secondary School"
                        component="div"
                        className="invalid-feedback d-block"
                      />
                    </fieldset>

                    <FormGroup
                      style={{
                        display:
                          formik.values["Completed Secondary School"] === "Yes"
                            ? "block"
                            : "none",
                      }}
                    >
                      <Label for="CXC or O'level passes">
                        If yes, did you obtain any CXC or O'level passes (grades
                        I, II, III)?
                      </Label>
                      <Input
                        innerRef={this.form}
                        type="select"
                        onChange={formik.handleChange}
                        name="CXC or O'level passes"
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="1 pass">1 pass</option>
                        <option value="2 passes">2 passes</option>
                        <option value="3 passes">3 passes</option>
                        <option value="4 or more passes">
                          4 or more passes
                        </option>
                      </Input>
                    </FormGroup>
                    <FormGroup
                      style={{
                        display:
                          formik.values["Completed Secondary School"] === "Yes"
                            ? "block"
                            : "none",
                      }}
                    >
                      <Label for="Subjects Passed">
                        Please outline the subjects passed:
                      </Label>
                      <Field
                        as={Input}
                        onChange={formik.handleChange}
                        type="textarea"
                        name="Subjects Passed"
                        placeholder="Enter subjects passed"
                      />
                    </FormGroup>

                    <fieldset className="form-group">
                      <legend>
                        Did you obtain any other certification or
                        qualifications?
                      </legend>
                      <Label className="custom-radio custom-control custom-control-inline">
                        <Field
                          as={Input}
                          type="radio"
                          name="Other Certifications or Qualifications"
                          value="Yes"
                          label="Yes"
                          inline="true"
                        />
                        Yes
                      </Label>
                      <Label className="custom-radio custom-control custom-control-inline">
                        <Field
                          as={Input}
                          type="radio"
                          name="Other Certifications or Qualifications"
                          value="No"
                          label="No"
                          inline="true"
                        />
                        No
                      </Label>
                      <ErrorMessage
                        name="Other Certifications or Qualifications"
                        component="div"
                        className="invalid-feedback d-block"
                      />
                    </fieldset>

                    <FormGroup>
                      <Label for="Other Certifications or Qualifications Details">
                        If Yes, give details:
                      </Label>
                      <Field
                        as={Input}
                        type="textarea"
                        name="Other Certifications or Qualifications Details"
                        placeholder="Details"
                      />
                    </FormGroup>

                    <fieldset className="form-group">
                      <legend>
                        Have you completed any Courses/Programmes? (e.g. SERVOL,
                        MUST/HYPE/YTEPP/CCC)
                      </legend>
                      <Label className="custom-radio custom-control custom-control-inline">
                        <Field
                          as={Input}
                          type="radio"
                          name="Completed Any Courses/Programmes"
                          value="Yes"
                          label="Yes"
                          inline="true"
                        />
                        Yes
                      </Label>
                      <Label className="custom-radio custom-control custom-control-inline">
                        <Field
                          as={Input}
                          type="radio"
                          name="Completed Any Courses/Programmes"
                          value="No"
                          label="No"
                          inline="true"
                        />
                        No
                      </Label>
                      <ErrorMessage
                        name="Completed Any Courses/Programmes"
                        component="div"
                        className="invalid-feedback d-block"
                      />
                    </fieldset>
                    <FormGroup>
                      <Label for="Completed Courses/Programmes Details">
                        If Yes, state which:
                      </Label>
                      <Field
                        as={Input}
                        type="textarea"
                        name="Completed Courses/Programmes Details"
                        placeholder="Please state"
                      />
                    </FormGroup>
                    <fieldset className="form-group">
                      <legend>
                        Have you been referred by a current Subway employee?{" "}
                      </legend>
                      <Label className="custom-radio custom-control custom-control-inline">
                        <Field
                          as={Input}
                          type="radio"
                          name="Referred"
                          value="Yes"
                          label="Yes"
                          inline="true"
                        />
                        Yes
                      </Label>
                      <Label className="custom-radio custom-control custom-control-inline">
                        <Field
                          as={Input}
                          type="radio"
                          name="Referred"
                          value="No"
                          label="No"
                          inline="true"
                        />
                        No
                      </Label>
                      <ErrorMessage
                        name="Referred"
                        component="div"
                        className="invalid-feedback d-block"
                      />
                    </fieldset>
                    <FormGroup
                      style={{
                        display:
                          formik.values["Referred"] === "Yes"
                            ? "block"
                            : "none",
                      }}
                    >
                      <Label for="Completed Courses/Programmes Details">
                        If yes, state the employee's name
                      </Label>
                      <Field
                        as={Input}
                        type="input"
                        name="Referred Details"
                        placeholder="Please state"
                      />
                    </FormGroup>
                    <Button type="submit">Submit</Button>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export default Careers;
