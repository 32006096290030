import React from 'react';


function Optgroup({ label, items, optValue, optLabel }) {
  return (
    <optgroup label={label}>
      {items.map(i => <option key={i[optValue]} value={i[optValue]}>{i[optLabel]}</option>)}
    </optgroup>
  );
}

export default Optgroup;
